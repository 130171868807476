var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from 'axios';
import { store } from '../store';
import { logoff, setMaintenanceMode, setOAuthTokens } from '../actions';
import { getConfig } from '../utils/config';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { getOAuthTokensWithRefreshToken } from './oAuthService';
const refreshAuthLogic = (failedRequest) => __awaiter(void 0, void 0, void 0, function* () {
    const { refreshToken, } = store.getState().user;
    if (!refreshToken) {
        store.dispatch(logoff());
        return Promise.reject(failedRequest);
    }
    else {
        try {
            const { clientId, clientSecret } = getConfig().oAuth;
            const { access_token: token, refresh_token: newRefreshToken } = yield getOAuthTokensWithRefreshToken(refreshToken, clientId, clientSecret, window.location.origin);
            store.dispatch(setOAuthTokens(token, newRefreshToken));
            failedRequest.response.config.headers.authorization = `Bearer ${token}`;
            return Promise.resolve();
        }
        catch (e) {
            store.dispatch(logoff());
            return Promise.reject(failedRequest);
        }
    }
});
const handleRequest = (config) => {
    var _a, _b, _c;
    if (!((_a = config.url) === null || _a === void 0 ? void 0 : _a.includes((_c = (_b = getConfig()) === null || _b === void 0 ? void 0 : _b.oAuth) === null || _c === void 0 ? void 0 : _c.tokenURL))) {
        let token = store.getState().user.token;
        config.headers = Object.assign(Object.assign({}, config.headers), { authorization: `Bearer ${token}` });
    }
    return config;
};
const handleSuccess = (response) => response;
const handleReject = (error) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c;
    const responseStatusCode = (_a = error.response) === null || _a === void 0 ? void 0 : _a.status;
    if (responseStatusCode) {
        if (responseStatusCode === 400 && ((_c = (_b = error.config) === null || _b === void 0 ? void 0 : _b.url) === null || _c === void 0 ? void 0 : _c.endsWith('/oauth/token'))) {
            store.dispatch(logoff());
        }
        else if (responseStatusCode === 503 || responseStatusCode === 504) {
            store.dispatch(setMaintenanceMode());
            store.dispatch(logoff());
        }
        else {
            return Promise.reject(error);
        }
    }
    return Promise.reject(error);
});
const apiClient = axios.create();
apiClient.interceptors.response.use(handleSuccess, handleReject);
// @ts-ignore
apiClient.interceptors.request.use(handleRequest);
createAuthRefreshInterceptor(
// @ts-ignore
apiClient, refreshAuthLogic);
export default apiClient;
